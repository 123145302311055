.windowsIntroTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("../../Assets/images/windows/ourWorks/ourWork-bottom-intro.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f0efee;

  margin-top: -3px;
  padding-top: 50px;
  padding-bottom: 50px;

  @media only screen and (max-width: 1440px) {
  }
  @media only screen and (max-width: 980px) {
    justify-content: flex-start;
    width: 100%;
    background-size: cover;
    background-position-x: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .windows__content {
    max-width: 1440px;
    width: 100%;
    padding-left: 60px;
    @media only screen and (max-width: 980px) {
      margin-left: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: linear-gradient(
        180deg,
        rgba(245, 244, 249, 0.9009978991596639) 0%,
        rgba(255, 255, 255, 0.8452556022408963) 45%,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    h2 {
      color: #19569c;
      margin-bottom: 30px;
      text-shadow: none;
      font-weight: 500;
      @media only screen and (max-width: 980px) {
        font-weight: 500;
        margin-top: 50px;
        margin-bottom: 15px;
      }
    }
    h3 {
      font-weight: 300;
      color: #19569c;
      max-width: 720px;
      @media only screen and (max-width: 1440px) {
        max-width: 850px;
      }
      @media only screen and (max-width: 980px) {
        font-weight: 400;
      }
    }
    button {
      width: 225px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;

      margin-top: 60px;
      @media only screen and (max-width: 980px) {
        width: 180px;
        height: 50px;
      }
    }
  }
}
