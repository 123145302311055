/* About Section 1 */

.about-section-1 {
  position: relative;
  padding: 140px 0 100px 0;
  text-align: center;
}

.about-section-1:before {
  content: "";
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  @media screen and (max-width: 989px) {
    height: 130px;
    top: 0px;
  }
}

.about-section-1 .container {
  max-width: 980px;
  position: relative;
  z-index: 5;
}

.about-section-1 h3 {
  line-height: 1.2;
  color: #2f2f2f;
  font-weight: normal;
  padding: 0 0 30px 0;
  font-family: "Quicksand", "Quicksand", serif;
}

.about-section-3 h3 span,
.about-section-1 h3 span {
  display: block;
  text-align: center;
  text-shadow: 3.993px 3.009px 2px rgba(216, 216, 216, 0.9);
  color: #424242;
  font-size: 60px;
  line-height: 64px;
  font-weight: 200;
  padding: 0 0 10px 0;
}
.about-section-1 p {
  padding: 0 0 20px 0;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

@media only screen and (max-width: 1220px) {
  .about-section-1 {
    padding: 80px 0;
  }
  .about-section-1 h3 {
    font-size: 52px;
    line-height: 56px;
  }
  .about-section-1 p {
    font-size: 22px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 989px) {
  .about-section-1 {
    padding: 50px 0;
  }
  .about-section-1 h3 {
    padding-bottom: 0px;
    font-size: 42px;
    line-height: 46px;
  }
  .about-section-3 h3 span,
  .about-section-1 h3 span {
    // font-size: 22px;
    // line-height: 26px;
    font-size: 39px;
    line-height: 34px;
    font-weight: 300;
  }
  .about-section-3 h3 span {
    padding-bottom: 20px;
  }
  .about-section-1 p {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .about-section-1 h3 {
    font-size: 18px;
    line-height: 36px;
  }
}

/* About Section Image */

.about-section-image {
  position: relative;
  display: block;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-section-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* About Section 2 */

.about-section-2 {
  position: relative;
  padding: 40px 0 90px 0;
  text-align: center;
}

.about-section-2:before {
  content: "";
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  @media screen and (max-width: 989px) {
    top: 10px;
    height: 100px;
    width: 100%;
  }
}

.about-section-2 .container {
  max-width: 980px;
  position: relative;
  z-index: 5;
}
.about-section-2 h2 {
  padding: 0px 0px 30px;
  @media screen and (max-width: 989px) {
    font-size: 39px;
    line-height: 34px;
    font-weight: 300;
  }
}

.about-section-2 h3 {
  font-family: "Quicksand", serif;
  font-size: 72px;
  line-height: 76px;
  color: #2f2f2f;
  font-weight: normal;
  padding: 0 0 30px 0;
}

.about-section-2 h3 span {
  font-size: 50px;
  line-height: 54px;
  display: block;
}

.about-section-2 p {
  padding: 0 0 20px 0;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.about-section-2 .founders {
  padding: 80px 0 0 0;
  display: flex;
  justify-content: center;
}

.about-section-2 .founders .box {
  margin: 0 42px;
}

.about-section-2 .founders .box .image {
  position: relative;
  display: block;
  max-width: 367px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 989px) {
    max-width: 295px;
  }
}

.about-section-2 .founders .box .image img {
  // display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  // height: 210px;
  // max-width: 367px;
  border-radius: 5px;
}

.about-section-2 .founders .box .image .imgContainer {
  width: 270px;
  height: 233px;
}

.about-section-2 .founders .box .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: transparent;
  border: solid 1px #43c4e4;
  border-radius: 5px;
  transform: translate(-8px, -8px);
  z-index: 5;
}

.about-section-2 .founders .box .name {
  padding: 20px 0 0 0;
  display: block;
  font-size: 30px;
  line-height: 34px;
  color: #000000;
  font-family: Georgia, serif;
}

.about-section-2 .founders .box .pos {
  color: #41c4eb;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-family: Georgia, serif;
  font-style: italic;
}

@media only screen and (max-width: 1220px) {
  .about-section-2 {
    padding: 80px 0;
  }
  .about-section-2 h3 {
    font-size: 52px;
    line-height: 56px;
  }
  .about-section-2 p {
    font-size: 22px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 989px) {
  .about-section-2 {
    padding: 50px 0;
  }
  .about-section-2 h3 {
    padding-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  .about-section-2 h3 span {
    font-size: 22px;
    line-height: 26px;
  }
  .about-section-2 p {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .about-section-2 .founders {
    padding-top: 40px;
    flex-wrap: wrap;
  }
  .about-section-2 .founders .box {
    margin: 0 auto;
    width: 100%;
    padding: 0 0 20px 0;
  }
  .about-section-2 .founders .box .image .imgContainer {
    width: unset;
    height: unset;
  }
}

@media only screen and (max-width: 480px) {
  .about-section-2 h3 {
    font-size: 32px;
    line-height: 36px;
  }
  .about-section-2 .founders .box .name {
    font-size: 26px;
    line-height: 30px;
    padding-top: 0px;
  }
}

/* About Section 3 */

.about-section-3 {
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: cover;
  text-align: right;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bathrooms__about-us-call-to-action {
  background: url(../../Assets/images/about_section_3_bg.jpg) no-repeat 0 center;
}

.kitchens__about-us-call-to-action {
  background: url(../../Assets/images/kitchens/home/home-footer-bg.png)
    no-repeat left 20% bottom;
}

.about-section-3 .container {
  max-width: 1180px;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.about-section-3 .cnt {
  max-width: 600px;
  display: block;
  width: 100%;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
}

.about-section-3 .cnt:after {
  content: "";
  display: block;
  clear: both;
}

.about-section-3 .cnt p {
  font-size: 36px;
  line-height: 48px;
  color: #000000;
  text-align: right;
  padding: 0 0 50px 0;
}

.about-section-3 .cnt p span {
  display: block;
  font-size: 24px;
  line-height: 36px;
}

.about-section-3 .cnt .blue-btn {
  float: right;
}

.about-section-3:before {
  content: none;
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 130px;
  top: 0px;
  @media screen and (max-width: 989px) {
    top: 10px;
    width: 100%;
    height: 100px;
  }
}

@media screen and (max-width: 1450px) {
  .kitchens__about-us-call-to-action {
    background-position-x: left;
  }
}

@media screen and (max-width: 1100px) {
  .kitchens__about-us-call-to-action {
    background-position-x: -20%;
    background-blend-mode: color;
  }
}

@media screen and (max-width: 860px) {
  .kitchens__about-us-call-to-action {
    background-position: center bottom;
  }
}

.about-section-4 {
  background-color: #eff1f0;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 40px;
      a {
        margin-top: 20px;
        margin-left: -30px;
      }
    }
    h2 {
      margin-left: -20px;
      padding-bottom: 20px;
    }
    ul {
      font-size: 25px;
      li {
        list-style-type: disc;
        .pos {
          color: #41c4eb;
        }
      }
    }
  }
}
.home-intro:before {
  @media screen and (max-width: 989px) {
    top: 10px;
    width: 100%;
    height: 100px;
  }
}
.home-intro.about-us p {
  padding: 0 0 20px 0;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  @media screen and (max-width: 989px) {
    font-size: 18px;
    line-height: 22px;
  }
}
.home-intro.about-us .blue-btn {
  margin-top: 50px;
}
.about-section-5 {
  margin-top: 30px;
  .centerText {
    text-align: center;
    h3 {
      font-size: 40px;
    }
    p {
      font-weight: 700;
      padding: 0 100px;
    }
  }
  .agmOffice {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .agmInfo {
      display: flex;
      width: 750px;
    }
    .section-1,
    .section-2 {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .sectionContainer {
        display: flex;
        h3 {
          font-size: 1.17em;
          color: #424242;
          font-family: "lato", sans-serif !important;
        }
        .icon {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: linear-gradient(
            90deg,
            rgb(70, 191, 238) 0%,
            rgb(41, 220, 224) 100%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          margin-right: 10px;
          img {
            height: 20px;
            fill: #fff;
          }
        }
      }
    }
    .section-1 {
      .sectionContainer:first-child {
        margin-bottom: 30px;
      }
    }
    .section-2 {
      .information {
        img {
          filter: invert(99%) sepia(1%) saturate(500%) hue-rotate(283deg)
            brightness(108%) contrast(100%);
        }
      }
    }
  }
}
@media only screen and (max-width: 1165px) {
  .about-section-5 {
    .centerText {
      p {
        padding: unset;
      }
    }
    .agmOffice {
      flex-direction: column;
      align-items: center;
      .agmInfo {
        width: unset;
      }
    }
  }
}
@media only screen and (max-width: 989px) {
  .about-section-4 {
    .container {
      div {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          margin-left: unset;
        }
      }
      h2 {
        font-size: 39px;
        line-height: 34px;
        font-weight: 400;
      }
      .blue-btn {
        padding: 10px 15px;
      }
      ul li {
        margin: 20px 0px;
        line-height: 20px;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
  .about-section-3 {
    min-height: 50px;
    padding: 50px 0;
  }
  .about-section-3 .container {
    justify-content: center;
  }
  .about-section-3 .cnt p {
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 25px;
  }
  .about-section-3 .cnt p span {
    font-size: 26px;
    line-height: 30px;
  }
  .about-section-3 .cnt .blue-btn {
    float: none;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 989px) {
  .work-steps {
    padding: 50px 0;
  }
  .work-steps ul li h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .work-steps ul li {
    width: 100%;
  }
  .work-steps ul li .cnt {
    padding-left: 60px;
  }
  .work-steps ul li:before {
    left: 10px;
  }
  .about-section-5 .agmOffice {
    .picture {
      display: none;
    }
    .agmInfo {
      display: initial;
      .section-1 {
        .sectionContainer {
          margin-bottom: 0;
        }
        .sectionContainer:nth-child(2) {
          display: none;
        }
      }
      .section-2 {
        max-width: 208px;
        .sectionContainer {
          margin-bottom: 20px;
        }
        .sectionContainer:nth-child(2) {
          .icon {
            width: 56px;
          }
          .content {
            span {
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .about-section-3 .cnt p {
    font-size: 22px;
    line-height: 26px;
  }
  .about-section-3 .cnt p span {
    font-size: 18px;
    line-height: 22px;
  }

  .work-steps ul li .cnt {
    padding-left: 0px;
  }

  .about-section-4 {
    .container {
      div {
        padding: 10px;
      }

      ul {
        li {
          font-size: 18px;
          margin: 10px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .agmOffice {
    .agmInfo {
      .section-1 {
        .sectionContainer .icon:first-child {
        }
      }
    }
  }
  .about-section-5 .agmOffice .section-1,
  .about-section-5 .agmOffice .section-2 {
    padding: 0px;
  }
  .about-section-5 .agmOffice .section-1 .sectionContainer .icon img,
  .about-section-5 .agmOffice .section-2 .sectionContainer .icon img {
    height: 15px;
    width: 36px;
  }
}
.about-section-3.windows {
  background-image: url("../../Assets/images/windows/ourWorks/ourWork-bottom-intro.png");
  padding-bottom: 30px;
  .container {
    justify-content: flex-start;

    p {
      text-align: left;
    }
    .blue-btn {
      float: left;
    }
  }
}
