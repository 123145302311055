// .readySterted.vinil {
//   display: flex;
//   justify-content: end;
//   width: 100%;
//   height: 740px;
//   margin-top: 40px;
//   // background-image: url("../../Assets/images/windows/vinil/wnds_top.png");
//   background-size: cover;
//   .content {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 45%;
//     h2,
//     h3 {
//       color: #19569c;
//       margin-bottom: 40px;
//     }
//     .contentList {
//       margin-bottom: 60px;
//       .contentList__Item {
//         display: flex;
//         font-weight: 600;
//         margin-bottom: 25px;
//         p {
//           margin-left: 20px;
//         }
//       }
//     }
//     button {
//       width: 225px;
//       height: 60px;
//       font-size: 20px;
//       line-height: 30px;
//       font-weight: 600;
//       background: linear-gradient(#0d8bc1, #19569c);
//       border: none;
//       border-radius: 4px;
//       color: #fff;
//       font-size: 23px;
//       line-height: 30px;
//       font-family: "Quicksand", sans-serif;
//       font-weight: 300;
//     }
//   }
// }
.mobileSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.d-flex.checkmark-box.container::before {
  display: none;
}
.d-flex.checkmark-box.container {
  @media only screen and (max-width: 1440px) {
    height: auto;
  }
  @media only screen and (max-width: 980px) {
    justify-content: center;
    align-items: center;
  }
}
.d-flex {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  @media only screen and (max-width: 980px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.container {
    max-width: 1790px;
  }
}
.middleImg {
  max-width: 540px;
  width: 75%;
  align-self: center;
}
.cnt {
  @media only screen and (max-width: 980px) {
    align-items: center;
    text-align: left;
  }
  &.patio {
    max-width: 1920px;
    padding: 20px;
    @media only screen and (max-width: 980px) {
      align-items: center;
    }
    .listContainer {
      align-items: center;
      ul {
        margin: 0 50px;
        @media only screen and (max-width: 980px) {
          margin: 0;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
        @media only screen and (max-width: 567px) {
          width: 100%;
        }
        li {
          margin-top: 7px;
          @media only screen and (max-width: 980px) {
            display: flex;
            line-height: 25px;
          }

          .imgWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            min-width: 36px;

            img {
              width: 36px;
            }
          }
          b {
            width: 80%;
            align-items: flex-start;
            justify-content: center;
            @media only screen and (max-width: 980px) {
              width: 100%;
            }
          }
        }
      }
    }
    .listContainerDesktop {
      ul {
        flex: 1;
        li {
          display: flex;
          .imgWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            min-width: 36px;
            img {
              width: 36px;
            }
          }
          b {
            width: 80%;
          }
        }
      }
    }
  }

  h2 {
    color: #fff;
    width: 100%;
    font-size: 3.2em;
    margin-bottom: 30px;
    text-shadow: none;
    font-weight: 500;
    @media only screen and (max-width: 980px) {
      font-size: 2em;
      text-align: center;
    }
  }
  p {
    color: #fff;
    @media only screen and (max-width: 980px) {
      text-align: center;
    }
  }
  b {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
  }
  .float-left {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: brightness(20);
    width: 29px;
    padding-right: 6px;
  }
  .blue-btn.windows {
    margin: 0 !important;
    border-radius: 7px;
    background: #fff;
    color: #18599c;
    font-weight: 400;
    font-size: 22px;
    box-shadow: none;
    box-shadow: none !important;
    border-bottom: 5px solid #dadada;
    width: max-content;
  }
  .centered-btn {
    @media only screen and (max-width: 980px) {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .blue-btn.desktop-btn.windows {
      @media only screen and (max-width: 980px) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.doors {
  background-position: cover;
  @media only screen and (max-width: 1440px) {
    align-self: flex-end;
  }
  @media only screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ul {
    @media only screen and (max-width: 1440px) {
      margin-left: 0;
    }

    li {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;

      @media only screen and (max-width: 980px) {
        display: flex;
        width: 100%;
        text-align: left;
        font-size: 15px;

        line-height: 20px;
      }
      .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        min-width: 36px;
        @media only screen and (max-width: 980px) {
          width: 29px;
          min-width: 29px;
        }
      }
    }
  }
  .windows {
    @media only screen and (max-width: 980px) {
      align-self: center;
      width: 140px;
      height: 40px;
    }
  }
}
