.agreement-text {
  font-size: 13px;
  line-height: 18px;
}

.text-center {
  text-align: center;
}

.MuiInputBase-input {
  font-weight: 400 !important;
}

.promoStampOffer{
  max-width: 800px;
  margin: 0 auto;
  top: 70px;
  background-color: rgba(17, 17, 17, 0.6705882353);
  border-radius: 3px;
  position: relative;
  color: #fff;
  z-index: 2;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  font-weight: 400;
  text-align: center;
  padding: 20px 10px;

  width: 100%;
  height: auto;
  left: 0;
  background-image: linear-gradient(180deg, transparent, #1a579c);

  border-radius: 0px;

  .closeBtn {
    display: none;
    width: 30px;
    padding: 2px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: -10px;
    color: #1863a5;
    background-color: #fff;
    cursor: pointer;
  }


  .topPar span{
    text-transform: uppercase;
    font-weight: 700;
  }

  .middleOffer {
    text-align: left;
    padding: 15px 20px;
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 0.8em;
    color: #e4e4e4;
    line-height: 20px;

    &.mobile{
      text-align: center;
      margin: 10px 0 10px 0;
      padding: 10px 20px;
      display: none;
    }

    &.image strong{
      position: relative;
    }

    &.image strong::after{
      content: '';
      width: 40px;
      height: 50px;
      background-image: url("../../Assets/images/plus.png");
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      left: 0;
      margin-top: -18px;
      margin-left: -29px;
    }

    &.image.extra.desk strong::after{
      margin-top: -38px;
    }
  }

  .button{
    background: linear-gradient(#0d8bc1, #19569c);
    border: none;
    border-radius: 4px;
    padding: 10px;
    line-height: 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin: 10px;
    font-size: 0.9em;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3), 2px 2px 2px rgba(0, 0, 0, 0.1);

    &:hover{
      background: linear-gradient(#12a4e3, #19569c);
      box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.4), 2px 2px 2px rgba(0, 0, 0, 0.25);
    }

    a {
      font-weight: 700;
      color: #fff;
    }
  }

  .endPart {
    font-size: 0.8em;
    line-height: 16px;
    margin-top: 20px;
  }

  // @media only screen and (max-width: 1380px) {
  //   top: 500px;
  // }
  @media only screen and (max-width: 980px) {  
    margin: 0 13px;
    width: calc(100% - 40px);
    // .middleOffer {
    //   &.image strong::after{
    //     margin-top: -20px;
    //     margin-left: -320px;
    //   }
    // }

  }



    .middleOffer {
      &.desk{
        display: none;
      }
      &.mobile{
        display: block;
      }

    }

    .closeBtn {
      display: block;
    }

    .button{
      margin: 0 auto;
      max-width: 300px;
      margin-bottom: 16px;
    }

  // @media only screen and (max-width: 540px) {
  //   top: 520px;
  //   font-size: 0.9em;

  //   .middleOffer.mobile{
  //     margin: 10px 0 10px 0;
  //   }
  // }

  // @media only screen and (max-width: 440px) {
  //   top: 480px;
  // }
}