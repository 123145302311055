.ourWorkTopSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  background-image: url("../../../Assets/images/windows/ourWorks/ourWork-topBaner.png");
  background-size: cover;
  margin-bottom: 70px;
  h2 {
    color: #19569c;
  }
}
