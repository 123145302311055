.grillsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  @media only screen and (max-width: 1440px) {
    max-width: 900px;
    padding: 30px;
  }

  h4 {
    font-weight: 500;
    text-align: center;
  }
  .grilsWindowsContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @media only screen and (max-width: 1440px) {
      justify-content: space-between;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
    .grilsWindowsContainer__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        margin-bottom: 40px;
        margin-top: 40px;
      }
      .grilsWindowsContainer__item_description {
        @media only screen and (max-width: 1440px) {
          font-size: 18px;
        }
      }
    }
  }
  .grilsBottomSection {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 80%;
    margin-top: 60px;
    @media only screen and (max-width: 1440px) {
      justify-content: space-between;
      width: 100%;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 300px;
      @media only screen and (max-width: 980px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100px;
      }
    }
    .grilsBottomSection__external {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 1440px) {
        width: 35%;
      }
      h3 {
        margin-bottom: 40px;
      }
    }
    .grilsBottomSection__internal {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 1440px) {
        width: 55%;
      }
      h3 {
        margin-bottom: 40px;
      }
    }
  }
}
