/* Header */
.header {
  padding: 0px 0;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  @media screen and (max-width: 989px) {
    padding: 15px 20px 0px;
  }
  @media screen and (max-width:767px) {
    margin-top: 56px;
  }
}

.header .header-cnt {
  display: flex;
  /* padding: 0 0 0 258px; */
  position: relative;
  min-height: 125px;
  align-items: center;
  // justify-content: space-between;
}

.header.home .header-cnt {
  min-height: 190px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1180px) {
    margin-left: 20px;
  }
}
.propmotinalMobile {
  display: none;
}
.header .logo {
  width: 185px;
  display: flex;
  // height: 120px;
  img {
    max-width: 100%;
    max-height: 100px;
  }
}

.header .menu-btn {
  display: none;
}

.header .navigation {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: static;
  flex: 1;
}

.header .navigation ul {
  // padding: 25px 0 0 0;
  display: flex;
  margin-left: 50px;
  justify-content: flex-start;

  @media screen and (max-width: 940px) {
    padding-top: 10px;
  }
  @media screen and (max-width: 420px) {
    display: flex;
    margin: 0 auto;
    padding: 15px 0 0 0;
    justify-content: center;
  }
}

.header .navigation ul li {
  padding: 0 0 0 20px;
  @media screen and (max-width: 1180px) {
    padding-left: 0px;
  }
}

.header .navigation ul li:first-child {
  padding: 0;
  // @media screen and (max-width:1024px) {
  //   padding-left:5px;
  // }
}

.header .navigation ul li .nav-btn {
  position: relative;
  padding: 42px 10px 12px 10px;
  display: block;
}

.navigation .nav-btn,
.navigation .nav_services {
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-align: center;
  transition: all 0.2s;
  font-family: "Quicksand", serif;
  @media screen and (max-width: 1367px) {
    font-size: 10px;
  }
}

.header .navigation ul li .nav-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 40px;
  height: 40px;
  background: url(../images/nav-icons.png);
}

.header .navigation ul li .nav-btn.window-btn::before {
  content: url("../../Assets/images/windows/header/window.png");
}
.header .navigation ul li .nav-btn.why-btn {
  display: block;
}
.header .navigation ul li .nav-btn.why-btn::before {
  content: url("../../Assets/images/why-us_agm.svg");
}

.header .navigation ul li .nav-btn.why-btn:before {
  background: none;
}
.header .navigation ul li .nav-btn.why-btn:hover {
  filter: invert(73%) sepia(82%) saturate(1000%) hue-rotate(158deg)
    brightness(120%) contrast(89%);
}
.header .navigation ul li .nav-btn.why-btn {
  padding-top: 42px;
}

.header .navigation ul li .nav-btn.news-btn {
  padding: 5px 0px 12px 0px;
}
.header .navigation ul li .nav-btn.news-btn:before,
.header .navigation ul li .nav-btn.window-btn:before {
  background: none;
}
.header .navigation ul li .nav-btn.window-btn:hover {
  filter: invert(73%) sepia(82%) saturate(1000%) hue-rotate(158deg)
    brightness(120%) contrast(89%);
}
.header .navigation ul li .nav-btn.window-btn {
  padding-top: 42px;
}
.nav_services {
  position: relative;
  padding-bottom: 12px;
}

.header .navigation ul li .nav-btn:after,
.nav_services:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  display: block;
  height: 2px;
  background: #43c4e4;
  transition: all 0.2s;
}

.header .navigation ul li .nav-btn.news-btn img {
  display: block;
  width: 50%;
  margin: 0px auto 5px auto;
  @media screen and (max-width: 676px) {
    display: block;
    width: 32px;
    margin: 0px auto 5px auto;
  }
}
.header .navigation ul li .nav-btn.news-btn {
  padding: 5px 0px 12px 0px;
}
.header .navigation ul li .nav-btn.news-btn:before {
  background: none;
}

.header .navigation ul li .nav-btn:hover,
.header .navigation ul li .nav-btn.active {
  color: #43c4e4;
}

.header .navigation ul li .nav-btn:hover:after,
.header .navigation ul .nav_services:hover:after,
.header .navigation ul li .nav-btn.active:after,
.header .navigation ul .nav_services:active:after {
  width: 100%;
  left: 0;
}

.header .navigation ul li .nav-btn.home-btn:before {
  background-position: 0 0;
}

.header .navigation ul li .nav-btn.home-btn:hover:before {
  background-position: 0 -100%;
}

.header .navigation ul li .nav-btn.hiw-btn:before {
  background-position: -40px 0;
}

.header .navigation ul li .nav-btn.hiw-btn:hover:before {
  background-position: -40px -100%;
}

.header .navigation ul li .nav-btn.ow-btn:before {
  background-position: -80px 0;
}

.header .navigation ul li .nav-btn.ow-btn:hover:before {
  background-position: -80px -100%;
}

.header .navigation ul li .nav-btn.about-btn:before {
  background-position: -120px 0;
}

.header .navigation ul li .nav-btn.about-btn:hover:before {
  background-position: -120px -100%;
}
.header .header-actions {
  display: flex;
  flex-direction: right;
  margin-left: auto;
  // justify-content: space-between;
  justify-content: flex-end;
  align-items: center;
}

.header .header-actions .login-btn {
  margin: 0 20px 0;
}

$headerHeight: 159px;

// .header .header-actions .login-btn:hover {  }
.home-section {
  height: auto;
  min-height: 750px;
  @media screen and (max-width: 1989px) {
    min-height: 580px;
  }
  // @media screen and (max-width:1024px) {
  //   // height: 80vh;
  //   min-height: unset;
  // }
  background: url(../images/banner_bathroom.png) no-repeat center bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-position-y: 0px;

  @media screen and (min-width: 1531px) {
    background-position-y: -5vw;
  }

  @media screen and (min-width: 2260px) {
    background-position-y: -7vw;
  }

  @media screen and (min-width: 2500px) {
    background-position-y: -8vw;
  }
  @media screen and (max-width: 1400px) {
    background-position-x: right;
  }
  @media screen and (max-width: 1367px) and (max-height: 769px) {
    margin-top: -70px;
    background-position-y: -10px;
  }
  @media screen and (max-width: 1280px) and (max-height: 800px) {
    margin-top: -70px;
    background-position-y: 0px;
  }

  @media screen and (max-width: 1362px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 1280px) and (max-height: 720px) {
    margin-top: -40px;
    background-position-y: 0px;
  }
  @media screen and (max-width: 989px) {
    display: block;
    height: auto;
    margin-top: 0;
    background: url(../images/home_background_mobile.png) no-repeat center top;
    background-size: cover;
    background-position-y: -135px;
  }
  @media screen and (max-width: 769px) {
    margin: 0px;
  }
  @media screen and (max-width: 550px) {
    min-height: unset;
    background-position-y: 0px;
  }
  @media screen and (max-width: 480px) {
    background-position-y: 0px;
    // height:355px;
  }
  @media screen and (max-width: 375px) {
    // height:350px;
  }
  @media screen and (max-width: 325px) {
    // height:310px;
  }
  @media screen and (max-width: 320px) and (max-height: 600px) {
    // height:320px;
    margin-bottom: 40px;
  }
  .home-banner {
    width: 100%;

    // width: 1200px;
    // display: flex;
    // justify-content: flex-end;
    // margin-top: 120px;

    .footerInfoBar {
      padding: 5px 15px;
      background: rgba(255, 255, 255, 0.75);
      position: absolute;
      right: 110px;
      bottom: 0px;
      display: flex;
      flex-direction: row;
      z-index: 99;
      &.desktop {
        @media screen and (min-width: 2000px) {
          right: 10vw;
        }

        @media screen and (max-width: 2000px) {
          right: 9vw;
        }

        @media screen and (max-width: 1800px) {
          right: 8vw;
        }

        @media screen and (max-width: 1520px) {
          right: 7vw;
        }

        @media screen and (max-width: 1443px) {
          right: 5vw;
        }

        @media screen and (max-width: 1203px) {
          right: 1vw;
        }

        // @media screen and (max-width: 1024px) {
        //   bottom: 90px;
        // }

        @media screen and (max-width: 989px) {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @media screen and (max-width: 989px) {
          display: flex;
          right: 35vw;
          bottom: 32px;
        }

        @media screen and (max-width: 895px) {
          right: 25vw;
        }

        @media screen and (max-width: 806px) {
          right: 25vw;
        }

        @media screen and (max-width: 731px) {
          right: 22vw;
        }

        @media screen and (max-width: 626px) {
          right: 15vw;
        }
      }
      .itemInfo {
        color: #303030;
        hr {
          margin-top: -3px;
          background: #1500ff;
          padding-top: 1px;
          border: none;
          @media screen and (max-width: 989px) {
            margin-top: 0px;
          }
        }
        &.hovered {
          cursor: pointer;
          // border-bottom: 1px solid #1500ff;
          padding-left: 0px;
          padding-bottom: 0px;
          margin-left: 15px;
          b,
          span {
            padding-left: 0px;
          }
          span {
            color: #1500ff;
            font-weight: 400;
          }
          &:hover {
            // color: #6dd8ff;
            text-shadow: 2px 2px 8px #fff;
            // border-bottom: 1px solid #6dd8ff;
          }
        }
        &:first-child {
          b,
          span {
            padding-left: 0px;
          }
        }

        &:last-child {
          b,
          span {
            padding-right: 0px;
          }
        }
        .border {
          border-right: 2px solid white;
        }
        b,
        span {
          padding: 0px 30px;
          line-height: 20px;
          @media screen and (max-width: 940px) {
            padding: 2px 5px;
          }
        }
        b {
          display: block;
          @media screen and (max-width: 545px) {
            padding-bottom: 5px !important;
          }
        }
        span {
          display: block;
        }
      }
      @media screen and (max-width: 1400px) {
        right: 0px;
      }
      @media screen and (max-width: 1280px) {
        bottom: 0px;
      }
      @media screen and (max-width: 940px) {
        font-size: 16px;
        line-height: 24px;
        right: 102px;
        bottom: 30px;
      }
      @media screen and (max-width: 989px) {
        font-size: 9px;
        right: 45px;
        padding: 3px 2px;
        bottom: 12px;
        z-index: 999;
        .itemInfo {
          b,
          span {
            padding: 0px 5px;
            text-align: left;
            height: 10px;
            line-height: 12px;
            margin-right: 0px;
          }
        }
      }
      @media screen and (max-width: 545px) {
        font-size: 14px;
        line-height: 10px;
        padding: 10px 2px;
        right: 80px;
        .itemInfo {
          b,
          span {
            line-height: 10px;
          }
        }
      }
      @media screen and (max-width: 480px) {
        font-size: 8px;
        padding: 3px 2px;
        right: 62px;
        bottom: 50px;

        .itemInfo.hovered {
          margin-left: 4px;
        }
      }
      @media screen and (max-width: 420px) {
        font-size: 8px;
        padding: 3px 2px;
        right: 50px;
        bottom: 36px;
      }
      @media screen and (max-width: 375px) {
        right: 45px;
        bottom: 25px;
      }
      @media screen and (max-width: 360px) {
        right: 44px;
        bottom: 35px;
      }
      @media screen and (max-width: 320px) {
        font-size: 8px;
        right: 54px;
        bottom: 28px;
        .itemInfo {
          span {
            padding: 0px 5px;
            line-height: 10px;
            height: 10px;
          }
        }
      }
      @media screen and (max-width: 320px) and (max-height: 600px) {
        right: 40px;
        bottom: 0px;
      }
    }
    .welcome-text {
      position: relative;
      // width: 500px;
      user-select: none;
      z-index: 2;
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
      flex-direction: column;
      & .welcome-sub {
        color: #46bfee;
        // color: #666;
        font-weight: 600;
      }
      @media screen and (max-width: 1400px) {
        margin-top: 0px;
      }
    }
    .home-form {
      width: 600px;
      max-width: 100%;
    }
    .agreement-text {
      font-size: 15px;
      padding-bottom: 0px;
      line-height: 15px;
    }
  }
  &.modified {
    @media screen and (max-width: 480px) {
      background-position-y: -20px;
      background-position-x: right;
    }
    .footerInfoBar {
      right: 0px;
      bottom: 0px;
    }
  }
}

.home-section.home-section--kitchen {
  // height: calc(80vh + #{$headerHeight});
  margin-top: -$headerHeight;
  overflow: hidden;
  background-position-y: bottom;
  height: 850px;
  display: flex;
  align-items: center;

  // @media screen and (max-width:670px) {
  //   align-items: flex-start;
  // }

  .home-page_po-image {
    position: absolute;
    right: 0;
    bottom: -70px;
    width: 38%;
    max-width: 650px;
    z-index: 2;

    @media screen and (max-width: 1770px) {
      max-width: 650px;
    }

    @media screen and (max-width: 1539px) {
      max-width: 600px;
    }

    @media screen and (max-width: 1392px) {
      max-width: 540px;
    }

    @media screen and (max-width: 1300px) {
      width: 65%;
    }

    @media only screen and (max-width: 940px) {
      width: 280px;
      bottom: -5px;
    }

    @media screen and (max-width: 676px) {
      width: 340px;
      bottom: -5px;
    }

    @media screen and (max-width: 504px) {
      bottom: -15px;
    }
  }

  // h4 {
  //   // color: #fff;
  // }

  p {
    // color: #fff;
    font-weight: 500;
  }

  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      // color: #fff;
      font-weight: 600;
    }
  }

  h1 {
    font-size: 66px;
    position: relative;
    z-index: 1;
    // text-shadow: none;
    // color: #fff;
    // font-weight: 700;

    &:before {
      // content: '';
      // background: url(../images/kitchens/home/bg-shadow.png) no-repeat center;
      // background-size: contain;
      // // background-position: 80% ​50;
      // width: 1056px;
      // height: 380px;
      // left: calc(calc(100% - 1056px)/2);
      // top: calc(calc(100% - 380px)/2);
      // position: absolute;
      // z-index: -1;

      @media only screen and (max-width: 940px) {
        display: none;
      }
    }
  }

  .desktop-btn {
    position: relative;
    z-index: 2;
    bottom: 0px;
  }

  .home-banner {
    .footerInfoBar {
      @media screen and (min-width: 941px) {
        font-size: initial;
        padding: 5px 15px;
        .itemInfo {
          b,
          span {
            height: initial;
            padding: 0px 30px 0 0;
            line-height: 20px;
          }
        }
      }

      @media screen and (max-width: 940px) {
        font-size: 13px;
        padding: 5px 5px;
        .itemInfo {
          b,
          span {
            height: initial;
            padding: 0px 0px;
            line-height: 16px;
          }
        }
      }

      @media screen and (min-width: 1770px) {
        right: 100px;
        bottom: 0px;
      }
    }
  }
}

.home-section .header {
  padding: 35px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-actions {
  flex: 1;
  justify-content: space-between;
}
.header-actions .promotinal {

    cursor: pointer;
    position: relative;
    top: 0;
    margin-left: 3px;
    max-width: 200px;
    opacity: .9;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,.3));

    &:hover{
      filter: drop-shadow(2px 4px 6px rgba(0,0,0,.6));
    }

  @media (max-width: 1180px) {
    width: 150px;
    margin-left: 10px;
    top: -7px;
  }
  @media (max-width: 989px) {
    top: -18px;
  }
  @media (max-width: 980px) {
    margin-left: -5px;
    width: 130px;
    top: -16px;
  }
  @media (max-width: 940px) {
    display: none;
  }
}
.header-actions .promotinal img {
  width: 100%;
}
@media screen and (max-width: 2200px), (max-height: 1400px) {
  .home-section.home-section--kitchen {
    .welcome-text {
      margin-top: -30px;
      h1 {
        padding-bottom: 15px;
        // font-size:  50px;
        // margin-top: -20px;
      }

      p {
        padding-bottom: 15px;
      }
    }
    // background-size: 110% auto;

    // background-position: 20% -100px;
  }
}

@media screen and (max-width: 1800px) {
  .home-section.home-section--kitchen h1 {
    font-size: 66px;
  }
}

@media only screen and (max-width: 1080px) {
  .header .empty-btn,
  .header .blue-btn {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;
  }
  // .header .header-cnt {
  //     padding-left: 230px;
  // }
}

@media only screen and (max-width: 940px) {
  #root {
    width: 100vw;
    overflow-x: hidden;
  }
  .home-section .header {
    padding: 10px 0;
    position: static;
  }
  .header .header-cnt,
  .header.home .header-cnt {
    min-height: 20px;
    padding: 0;
  }
  .header .logo {
    position: static;
    // margin: 0 auto;
    transform: translate(0, 0);
    display: block;
  }
  .propmotinalMobile {
    position: relative;
    top: -8px;
    margin-left: 10px;
    display: block;
    max-width: 160px;
    opacity: .9;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,.3));

    @media only screen and (max-width: 540px) {
      width: 150px;
      margin-left: 4px;
    }
    @media only screen and (max-width: 400px) {
      width: 100px;
      margin-left: 8px;
      top: -10px;
    }
  }

  .header .menu-btn {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 30px;
    height: 18px;
    font-size: 0;
    line-height: 0;
    text-indent: -100%;
    border: 0px;
  }
  .header .menu-btn:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
  }
  .header .menu-btn span,
  .header .menu-btn span:before,
  .header .menu-btn span:after {
    width: 100%;
    height: 3px;
    display: block;
    background-color: #666666;
    transition: all 0.2s;
    border-radius: 0px;
  }
  .header .menu-btn span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
  }
  .header .menu-btn span:before {
    content: "";
    position: absolute;
    top: -8px;
  }
  .header .menu-btn span:after {
    content: "";
    position: absolute;
    top: 8px;
  }
  .header .navigation {
    position: absolute;
    top: 100%;
    right: -120%;
    width: 100%;
    max-width: 450px;
    display: block;
    border-radius: 4px;
    background-image: -moz-linear-gradient(
      90deg,
      rgb(70, 191, 238) 0%,
      rgb(41, 220, 224) 100%
    );
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(70, 191, 238) 0%,
      rgb(41, 220, 224) 100%
    );
    background-image: -ms-linear-gradient(
      90deg,
      rgb(70, 191, 238) 0%,
      rgb(41, 220, 224) 100%
    );
    transition: all 0.2s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .header .navigation ul:after {
    content: "";
    display: block;
    clear: both;
  }
  .header .navigation ul li {
    float: left;
  }
  /* .header .navigation ul li .nav-btn { color: #ffffff; } */
  /* .header .navigation ul li .nav-btn:after { background-color: #ffffff; } */
  .header .navigation ul li .nav-btn:hover,
  /* .header .navigation ul li .nav-btn.active { color: #ffffff; } */
  .header .navigation ul li .nav-btn.home-btn:hover:before {
    background-position: 0 0;
  }
  .header .navigation ul li .nav-btn.hiw-btn:hover:before {
    background-position: -40px 0;
  }
  .header .navigation ul li .nav-btn.ow-btn:hover:before {
    background-position: -80px 0;
  }
  .header .navigation ul li .nav-btn.about-btn:hover:before {
    background-position: -120px 0;
  }
  .header .header-actions {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
  .header .header-actions .login-btn,
  .header .header-actions .start-btn {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    background: #ffffff;
    color: #5a676b;
    text-align: center;
  }
  .header .header-actions .login-btn:hover,
  .header .header-actions .start-btn:hover {
    background: #5a676b;
    color: #ffffff;
  }
  .header.opened .menu-btn span {
    background: transparent;
  }
  .header.opened .menu-btn span:before {
    transform: rotate(45deg);
    top: 0;
  }
  .header.opened .menu-btn span:after {
    transform: rotate(-45deg);
    top: 0;
  }
  .header.opened .navigation {
    right: 0;
    background: #fff;
    z-index: 999;
  }
  .home-section .home-banner .welcome-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: initial;
    user-select: none;
  }
  .home-section.home-section--kitchen {
    .welcome-text {
      margin-top: -100px;
      user-select: none;
    }
  }

  .home-section.home-section--kitchen {
    height: 350px;
    padding-top: 159px;
    // background-size: 100% auto;

    h1 {
      font-size: 45px;
    }
  }
}

@media screen and (max-width: 676px) {
  .home-section.home-section--kitchen {
    height: 580px;
    padding-top: 140px;
    box-sizing: border-box;
    background-size: auto 75%;

    .welcome-text {
      margin-top: -180px;
      h1 {
        font-size: 2em;
        margin-top: 30px;
        padding-bottom: 8px;
      }
      p {
        // display: none;
        margin-top: -5px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .header .logo {
    width: 120px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header .menu-btn {
    right: 0;
  }
  .header .navigation ul li {
    padding-left: 5px;
  }
  .header .navigation ul li .nav-btn {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .header .header-actions .login-btn,
  .header .header-actions .start-btn {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 375px) {
  .header .navigation ul li .nav-btn {
    font-size: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 360px) {
  .header .navigation ul li .nav-btn {
    font-size: 10px;
  }
  .header .header-actions .login-btn,
  .header .header-actions .start-btn {
    font-size: 12px;
  }

  .home-section.home-section--kitchen {
    .welcome-text {
      h1 {
        font-size: 1.7em;
      }
    }
  }
}
.headerLogos {
  @media only screen and (max-width: 1700px) {
    display: none;
  }
  @media only screen and (max-width: 1300px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .logoContainer {
    margin-right: 15px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1367px) {
      width: 50px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.logoContainer.google {
  @media only screen and (max-width: 1777px) {
    display: none;
  }
}

.headerLogos {
  .logoContainer.best,
  .logoContainer.bild {
    width: 50px;
    height: 50px;
  }
}
.home-banner .desktop-btn {
  display: block;
  @media (max-width: 940px) {
    bottom: -30px;
  }

  @media (max-width: 900px) {
    bottom: -200px;
  }

  @media (max-width: 676px) {
    display: none;
  }
  @media screen and (max-width: 1367px) and (max-height: 769px) {
    font-size: 0.75rem;
    padding: 10px 20px;
  }
}
.desktop-visible {
  @media (max-width: 989px) {
    display: none;
  }
}
.mobile-visible {
  text-align: center;
  position: relative;
  display: none;

  .blue-btn {
    margin: auto;
    margin-top: 20px;
  }
  &.slider-box {
    margin-top: 20px;
  }
  // @media (max-width:940px) {
  //   // margin-top:45px;
  // }
  @media (max-width: 989px) {
    display: block;
  }
  @media (max-width: 676px) {
    display: block;
    &.slider-box {
      margin-top: 20px;
    }
    h4 {
      margin-top: 0px;
    }
  }
  @media (max-width: 375px) {
    margin-top: 45px;
  }
  @media (max-width: 320px) {
    margin-top: 50px;
  }
}
