.colorSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 60px;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 1440px) {
    max-width: 900px;
    padding: 30px;
  }
  @media only screen and (max-width: 980px) {
    padding: 30px;
  }
  h4 {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 500;
  }
  .colorContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    row-gap: 30px;
    column-gap: 30px;
    max-width: 1440px;
    @media only screen and (max-width: 980px) {
      padding: 20px;
    }
    .colorSection__item {
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 20px;
      width: 20%;
      height: 200px;
      @media only screen and (max-width: 1440px) {
        height: 150px;
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
        max-width: 329px;
        height: 200px;
      }
      h3 {
        color: #fff;
        @media only screen and (max-width: 1440px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 980px) {
          line-height: 30px;
          font-size: 30px;
        }
      }
    }
  }
}
