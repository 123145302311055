/* How it Works */

.home-hiw {
  background: #fafafa;
  position: relative;
  padding: 120px 0;
  text-align: center;
}

.home-hiw .container {
  position: relative;
  z-index: 5;
}

.home-hiw:before {
  content: "";
  display: block;
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat center center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.home-hiw h2 {
  display: block;
  padding: 0 0 90px 0;
}

.home-hiw ul {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  @media screen and (max-width: 1060px) {
    justify-content: center;
  }
}

.home-hiw ul li {
  padding: 380px 50px 0 50px;
  position: relative;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1060px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.home-hiw ul li:before {
  content: "";
  position: absolute;
  top: 17px;
  width: auto;
  height: 0px;
  border-top: dotted 2px #35bfc8;
  display: block;
}

.home-hiw ul li[data-number="1"]:before {
  left: 50%;
  right: 0;
}

.home-hiw ul li[data-number="2"]:before {
  left: 0;
  right: 0;
}

.home-hiw ul li[data-number="3"]:before {
  left: 0;
  right: 50%;
}

.home-hiw ul li:after {
  content: attr(data-number);
  position: absolute;
  top: 0;
  left: 50%;
  width: 35px;
  height: 35px;
  transform: translateX(-50%);
  background: rgb(70, 191, 238) 0%;
  display: block;
  border-radius: 50%;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 600;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(34, 222, 221) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(34, 222, 221) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(34, 222, 221) 100%
  );
  background: linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  );
  z-index: 5;
}

.home-hiw ul li h3 {
  font-size: 30px;
  line-height: 34px;
  color: #000000;
  padding: 0 0 25px 0;
  display: block;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}

.home-hiw h3 .item-image {
  content: "";
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.home-hiw ul li[data-number="1"] h3 .item-image {
  background-repeat: no-repeat;
  background-position: center;
  width: 353px;
  height: 229px;
}

.home-hiw ul li[data-number="2"] h3 .item-image {
  background-repeat: no-repeat;
  background-position: center;
  width: 345px;
  height: 258px;
  top: 100px;
  background-size: cover;
}

.home-hiw ul li[data-number="3"] {
  padding-top: 130px;
}

.home-hiw ul li[data-number="3"] > div {
  width: 320px;
  height: 250px;
}

.home-hiw ul li[data-number="3"] img {
  max-width: 100%;
  max-height: 100%;
}

// .home-hiw ul li[data-number="3"] h3 .item-image {
//   background: url(../../Assets/images/work_truck_agm.png) no-repeat center
//     center;
//   width: 370px;
//   height: 260px;
//   top: 90px;
// }

.home-hiw ul li p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.work-steps ul li[data-number="2"] img {
  max-width: 450px;
}

.work-steps ul li[data-number="4"] .imageContainer {
  display: flex;
}

.work-steps ul li[data-number="4"] img {
  max-width: 500px;
  max-height: 270px;
  height: auto;
}

@media only screen and (max-width: 1060px) {
  .home-hiw ul {
    flex-wrap: wrap;
  }
  .home-hiw ul li {
    margin-bottom: 60px;
    width: 100%;
  }
  .home-hiw ul li[data-number="1"]:before,
  .home-hiw ul li[data-number="2"]:before,
  .home-hiw ul li[data-number="3"]:before {
    left: 0;
    right: 0;
  }
  .home-hiw ul li[data-number="3"] > div {
    justify-content: center;
    width: unset;
    display: flex;
  }
}

@media only screen and (max-width: 989px) {
  .home-hiw {
    padding: 50px 0 0px;
  }
  .home-hiw ul li {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 300px;
  }
  .home-hiw ul li h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-hiw h3 .item-image {
    top: 40px;
  }
  .home-hiw ul li[data-number="2"] h3 .item-image {
    top: 40px;
  }
  .home-hiw ul li h3 {
    margin-top: 15px;
  }
  // .home-section .home-banner .welcome-text p {
  //   display: none;
  // }
}

.video-intro {
  &.kitchens__how-works.how-works .banner img {
    margin-left: -10%;
  }

  &.how-works {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 350px;

    .banner {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: row;

      .content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .context {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .imgContainer {
          flex: 1;

          img {
            width: 450px;
          }
        }
      }
    }

    @media screen and (max-width: 1500px) {
      &.kitchens__how-works.how-works .banner img {
        margin-left: 0%;
      }
    }

    @media only screen and (max-width: 767px) {
      .banner {
        .content {
          flex-direction: column;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      background-image: none;
      padding-top: 10px;
      padding-bottom: 10px;
      h2 {
        font-size: 45px;
        color: #424242;
        line-height: 40px;
        margin-top: 30px;
      }

      h4 {
        font-size: 28px;
        font-style: inherit;
        margin-top: 10px;
      }

      .play-btn {
        display: none;
      }
    }
  }
}

/* Work Steps */

.work-steps {
  position: relative;
  padding: 100px 0 0 0;
}

.work-steps .container {
  max-width: 1180px;
  position: relative;
  z-index: 5;
  .bottomTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.work-steps ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 60px 0;
}

.work-steps {
  @media only screen and (max-width: 480px) {
    padding: 0px;
  }

  ul {
    li {
      position: relative;
      padding: 0 0 30px 0;
      width: 49.15%;
      @media only screen and (max-width: 480px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding: 10px 0 10px 0;
        // &:before {
        //   display: none !important;
        // }
      }
    }
  }
}

.work-steps ul li:before {
  content: attr(data-number);
  position: absolute;
  left: 16px;
  top: 18px;
  width: 35px;
  height: 35px;
  display: block;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background: linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 220, 224) 100%
  );
  font-weight: bold;
}

.work-steps ul li .cnt {
  background-image: -moz-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 3%,
    rgb(248, 247, 247) 47%,
    rgb(255, 255, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 3%,
    rgb(248, 247, 247) 47%,
    rgb(255, 255, 255) 100%
  );
  background-image: -ms-linear-gradient(
    -5deg,
    rgb(255, 255, 255) 3%,
    rgb(248, 247, 247) 47%,
    rgb(255, 255, 255) 100%
  );
  padding: 15px 10px 15px 80px;
  @media only screen and (max-width: 480px) {
    padding: 15px 10px 15px 10px;
    text-align: center;
  }
}

.work-steps ul li h3 {
  padding: 0 0 15px 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  color: #000000;
  font-family: "Quicksand", sans-serif;
}

.work-steps ul li p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.work-steps ul li img {
  display: block;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  height: auto;
}

.work-steps .blue-btn {
  margin: 0 auto;
}

/* Enjoy */
.enjoy {
  text-align: center;
  z-index: 10;
  position: relative;
  @media screen and (max-width: 989px) {
    transform: none;
  }
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  font-weight: 600;

  a {
    color: #41c4eb;
    text-decoration: underline;
  }
}

.relative {
  position: relative;
}

.enjoyImg {
  width: 100%;

  img {
    margin-top: -600px;
    @media screen and (max-width: 1024px) {
      margin-top: -300px;
    }
    @media screen and (max-width: 900px) {
      margin-top: 0px;
    }
    width: 100%;
    height: auto;
  }
}

.enjoy h2 {
  padding: 0px 0 70px;
  display: block;
  transform: translateY(-100px);
  @media screen and (max-width: 1024px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 989px) {
    transform: none;
    padding-bottom: 0px;
  }
}

.kitchen__enjoy h2 {
  padding: 0;
  margin-bottom: -130px;
  margin-top: -60px;
  position: relative;
  z-index: 2;
}

.enjoy h2 span {
  display: block;
  font-size: 30px;
  line-height: 34px;
}

@media screen and (max-width: 989px) {
  .kitchen__enjoy h2 {
    margin-bottom: 0;
    margin-top: 20px;
    padding: 20px 0;
  }
}
