#windowsEnjoy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  background-image: url("../../../Assets/images/windows/how-it-works/enjoy-window.png");
  background-size: cover;
  text-align: center;
  h2 {
    color: #19569c;
    position: relative;
    bottom: 40px;
    line-height: 140%;
  }
}
