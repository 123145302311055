/* Footer */
.realEstateLink__content {
  font-weight: 600;
  margin-right: 5px;

  &::after{
    content: ' | ';
    width: 10px;
    height: 20px;
    position: absolute;
    font-weight: 400;
  }

  &.last {
    &::after{
      display: none;
    }
  }

  &.first {
    &::before{
      content: ' | ';
      width: 10px;
      height: 20px;
      position: absolute;
      font-weight: 400;
      margin-left: -10px;
    }
  }
}

footer {
  padding: 80px 0 90px 0;
  border-top: solid 1px #f7f7f7;
  overflow-y: hidden;
  // margin-bottom: 117px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

footer .container {
  max-width: 1180px;
  padding: 0 20px;
  // overflow-y: hidden;
}

footer .footer-boxes {
  display: flex;
  justify-content: space-between;
  padding: 0 0 90px 0;
  @media only screen and (max-width: 980px) {
    justify-content: center;
  }
}

footer .footer-boxes .first {
  @media only screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 160px;
    margin-bottom: 25px;
  }

  width: 700px;
}

footer .footer-boxes .first p {
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  padding: 0 0 25px 0;
}
footer .footer-boxes .first .descriptionWindows {
  font-weight: 600;
}
footer .footer-boxes .secоnd {
  width: 300px;
}

footer .footer-boxes .secоnd ul {
  display: block;
}

footer .footer-boxes .secоnd ul li {
  position: relative;
  padding: 0 0 15px 55px;
  font-size: 18px;
  line-height: 24px;
  color: #7c8f91;
}

footer .footer-boxes .secоnd ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  font-size: 34px;
  line-height: 38px;
  color: #687b7d;
  font-weight: 400;
  font-family: "FontAwesome", Georgia, serif;
  font-weight: normal;
}

footer .footer-boxes .secоnd ul li.address:before {
  content: "\f041";
}

footer .footer-boxes .secоnd ul li.phone:before {
  content: "\f095";
  font-size: 26px;
  line-height: 30px;
}
.secоndWindows {
  @media only screen and (max-width: 980px) {
    margin-top: 20px;
  }
}
footer .footer-boxes .secоndWindows ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
footer .footer-boxes .secоndWindows ul li {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 980px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }
}
footer .footer-boxes .secоndWindows ul li a {
  margin-left: 20px;
  color: black;
  font-weight: 600;
}
footer .footer-boxes .secоndWindows ul li span {
  max-width: 300px;
  margin-left: 20px;
  color: black;
  font-weight: 600;
}

footer .footer-boxes .secоnd ul li.email:before {
  content: "\f003";
  font-size: 20px;
  line-height: 24px;
}

footer .footer-boxes .secоndWindows ul li a {
  max-width: 300px;
}
footer .footer-boxes .secоnd ul li a {
  color: #7c8f91;
}

footer .footer-boxes .secоnd ul li a:hover {
  text-decoration: underline;
}

footer .footer-boxes .third {
  position: relative;
  width: 380px;
  border: solid 1px #f3f3f3;
  background: #f2fefe;
  border-radius: 5px;
  padding: 55px 40px;
  min-height: 336px;
  box-sizing: border-box;
  text-align: center;
}

footer .footer-boxes .third:after {
  content: "";
  width: 210px;
  height: 249px;
  background: url(../images/grid.png) repeat 0 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  display: block;
  margin-left: 1px;
}

footer .footer-bottom p a.blue {
  color: #00b1e3;
  text-decoration: underline;
  font-weight: 800;
}

footer .footer-bottom p a.green {
  color: #1d3c5a;
  text-decoration: underline;
  font-weight: 800;
}

footer .footer-boxes .third h3 {
  position: relative;
  font-size: 30px;
  line-height: 34px;
  color: #000000;
  display: block;
  font-family: "Quicksand", sans-serif;
}

footer .footer-boxes .third h3 span {
  display: block;
}

footer .footer-boxes .third h3:after {
  content: "?";
  display: block;
  padding: 50px 0 0 0;
  color: #e4dbdb;
  font-size: 70px;
  line-height: 1.2;
}

footer .footer-bottom {
  padding: 70px 0 0 0;
  border-top: solid 1px #526264;
}

footer .footer-bottom .socials {
  padding: 0 0 40px 0;
  display: flex;
  justify-content: center;
  transform: scale(1.3);
}

footer .footer-bottom .socials .soc-btn {
  font-size: 0;
  line-height: 0;
  text-indent: -100%;
  margin: 0 7px;
}

footer .footer-bottom .socials .soc-btn:before {
  font-size: 24px;
  line-height: 28px;
  color: #687b7d;
  display: block;
  text-indent: 0;
  text-align: center;
  font-family: "FontAwesome", Georgia, serif;
  transition: all 0.2s;
  font-weight: normal;
}
footer .footer-bottom .socials .soc-btn.soc-btn-tk {
  margin-right: 23px;
}
footer .footer-bottom .socials .soc-btn.soc-btn-tk svg {
  fill: #777;
}
footer .footer-bottom .socials .soc-btn.soc-btn-tk svg:hover{
  fill: #2defea;
}

footer .footer-bottom .socials .soc-btn.soc-btn-f:before {
  content: "\f09a";
}

footer .footer-bottom .socials .soc-btn.soc-btn-f:hover:before {
  color: #3b5999;
}

footer .footer-bottom .socials .soc-btn.soc-btn-tw:before {
  content: "\f099";
}

footer .footer-bottom .socials .soc-btn.soc-btn-tw:hover:before {
  color: #55acee;
}

footer .footer-bottom .socials .soc-btn.soc-btn-ins:before {
  content: "\f16d";
}

footer .footer-bottom .socials .soc-btn.soc-btn-ins:hover:before {
  color: #e4405f;
}

footer .footer-bottom .socials .soc-btn.soc-btn-yt:before {
  content: "\f167";
}

footer .footer-bottom .socials .soc-btn.soc-btn-yt:hover:before {
  color: #cd201f;
}

footer .footer-bottom .socials .soc-btn.soc-btn-in:before {
  content: "\f0e1";
}

footer .footer-bottom .socials .soc-btn.soc-btn-in:hover:before {
  color: #0077b5;
}

footer .footer-bottom p {
  display: block;
  text-align: center;
  color: #687b7d;
  font-size: 16px;
  line-height: 20px;
}

footer .footer-bottom p a {
  color: #687b7d;
}

footer .footer-bottom p a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1028px) {
  footer .footer-boxes {
    flex-wrap: wrap;
    align-items: center;
  }
  footer .footer-boxes .first {
    width: 46%;
  }
  footer .footer-boxes .secоnd {
    width: 46%;
  }
  footer .footer-boxes .third {
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 989px) {
  footer {
    padding: 50px 0;
  }
  footer .footer-boxes {
    padding-bottom: 50px;
  }
  footer .footer-bottom {
    padding-top: 50px;
    margin-bottom: 20px;
  }

  .realEstateLink__content {
    display: block;
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
  }


}

@media only screen and (max-width: 600px) {
  footer .footer-boxes .first {
    width: 100%;
    text-align: center;
  }
  footer .footer-boxes .first:before {
    margin: 0 auto 35px auto;
  }
  footer .footer-boxes .first .blue-btn {
    margin: 0 auto;
  }

  footer .footer-boxes .secоnd {
    width: 100%;
    padding-top: 30px;
  }
  footer .footer-boxes .secоnd ul {
    display: table;
    margin: 0 auto;
  }
}

.floating-form {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  padding: 20px;
  top: 0px;
  left: 0px;
  background: #e5e5e5;
  z-index: 998;
  font-family: "Proxima Nova", Arial, Helvetica, sans-serif;
  color: #4c4c4c;
  font-size: 16px;
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #cacaca;
  overflow: hidden;

  .offer-top {
    text-align: center;
  }

  @media screen and (max-width: 1989px) {
    padding: 3px 20px 15px 20px;
  }

  @media screen and (max-width: 676px) {
    .floating-form {
      display: none;
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(9px, 4px) scale(0.65);
  }

  .float-logo {
    flex: 0 0 115px;
    &:after {
      content: "";
      border-right: 1px solid #c2c2c2;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      right: -25px;
    }
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
    color: #454545;
    transform: translate(14px, 13px) scale(1);
  }

  h2 {
    color: #839069;
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
    padding: 0;
    margin: 0;

    &.offer-text {
      color: #454545;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 2px solid #454545;
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      font-weight: bold;
    }

    text-align: center;
    // color: #fff;
    text-shadow: none;
    font-size: 26px;
    line-height: 26px;
  }

  button {
    &.MuiFab-root {
      box-shadow: none;
      height: 38px;
      border-radius: 4px;
      margin-left: 8px;
    }
  }

  .form-fields {
    display: flex;

    img {
      width: 140px;
      margin-right: 10px;
    }

    .MuiInputBase-input {
      border: 1px solid #333;
      border-radius: 0;
      background: white;
      padding: 8px 30px 8px 8px;
      font-size: 13px;
      height: 38px;
      box-sizing: border-box;
    }

    .MuiFormControl-root {
      margin-left: 8px;
    }
  }

  // .MuiFormLabel-root {
  //   color: white;
  // }

  // .MuiInput-underline {
  //   &:before {
  //     border-bottom: 1px solid rgb(255, 255, 255);
  //   }

  //   input {
  //     color: white;
  //     &::placeholder {
  //       color: white;
  //     }
  //   }
  // }
}

.floating-call-btn {
  @media screen and (max-width: 900px) {
    display: none;
  }
  position: fixed;
  bottom: 0px;
  right: 20px;
  padding: 10px 20px;
  background: #1d3c5a;
  color: #fff;
  z-index: 99;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  a {
    color: #fff;
    font-weight: 600;
  }

  &:before {
    font-family: "FontAwesome";
    content: "\f095";
    font-size: 26px;
    line-height: 30px;
    margin-right: 8px;
  }
}

.floating-call-btn-mobile {
  display: none;
  @media screen and (max-width: 900px) {
    display: initial;
  }

  a {
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    bottom: 120px;
    right: 20px;
    padding: 10px 20px;
    background-color: rgb(70, 191, 238);
    background: linear-gradient(
      90deg,
      rgb(70, 191, 238) 0%,
      rgb(41, 220, 224) 100%
    );
    background-image: -ms-linear-gradient(
      90deg,
      rgb(70, 191, 238) 0%,
      rgb(41, 220, 224) 100%
    );
    box-shadow: 3px 3px 4px #3e3e3e;
    color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: bounce 4s ease infinite;

    &:before {
      font-family: "FontAwesome";
      content: "\f095";
      font-size: 26px;
      line-height: 30px;
    }
  }
}

@keyframes bounce {
  0%,
  8%,
  12%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  90% {
    transform: translateY(-10px);
  }
}
footer .footer-boxes .first .blue-dark-windows-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 225px;
  height: 60px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  background: linear-gradient(#0d8bc1, #19569c);
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 23px;
  line-height: 30px;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.blue-dark-windows-btn.cta button {
  background-color: rgb(255 169 11);
  background: linear-gradient(90deg, rgb(229 148 0) 0%, rgb(255 169 11) 100%) !important;
}

.blue-dark-windows-btn.ctaB button {
  background-color: rgb(29 60 90);
  background: linear-gradient(90deg, rgb(20 47 74) 0%, rgb(22 84 144) 100%) !important;
}

.getQuote-btn-mobile{
  display: none;
  cursor: pointer;
  
  @media screen and (max-width: 900px) {
      display: block;
      position: fixed;
      left: 0;
      z-index: 1000;
      cursor: pointer;
  }

  span {
    position: fixed;
    color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    bottom: 0px;
    padding: 10px 20px;
    background-color: rgb(70, 191, 238);
    // background: linear-gradient( 90deg, rgb(70, 191, 238) 0%, rgb(41, 220, 224) 100% );
    background: linear-gradient(90deg, #d48901, #ffa500);
    box-shadow: 3px 3px 4px rgba(0,0,0, 0.6);
    color: #fff;
    z-index: 11199;
    display: flex;
    align-items: center;
    justify-content: center;
    width:  100%;
    height: 60px;
    // animation: bounce 4s ease infinite;


  }

}