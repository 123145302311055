.topDoors {
  display: flex;
  justify-content: center;
  column-gap: 80px;
  width: 100%;
  height: 70px;
  background-color: #19569c;
  @media only screen and (max-width: 980px) {
    background-color: #fff;
    flex-wrap: wrap;
    height: 8rem;
    column-gap: 2%;
    margin-bottom: 10px;
  }
  .topDoors__item {
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    color: #fff;
    padding-left: 54px;
    padding-right: 7px;
    margin-bottom: 3px;
    @media only screen and (max-width: 1440px) {
      row-gap: 20px;
      justify-content: center;
      width: auto;
      padding-left: 23px;
    }
    @media only screen and (max-width: 980px) {
      background-color: #19569c;
      width: 40%;
      margin-bottom: 8px;
      padding-left: 4%;
      font-size: 14px;
      justify-content: flex-start;
    }
    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
    a {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 980px) {
        margin-left: 20%;
      }
      @media only screen and (max-width: 650px) {
        margin-left: 5%;
      }
      @media only screen and (max-width: 500px) {
        margin-left: 0px;
      }
      img {
        margin-right: 7px;
        @media only screen and (max-width: 1440px) {
          row-gap: 20px;
          width: 35px;
          height: 35px;
        }
        @media only screen and (max-width: 980px) {
          row-gap: 10px;
          align-self: baseline;
          justify-content: flex-start;
          padding: 5px;
        }
      }
    }
    &.active {
      background-color: rgb(70 191 238);
    }
  }
}
