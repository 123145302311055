.productFeatures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h2 {
    color: #19569c;
    font-weight: 500;
    text-shadow: none;
  }
  .subtitle {
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    max-width: 1440px;
    margin: 40px 20px;
    margin-top: 40px;

    @media only screen and (max-width: 1440px) {
      max-width: 900px;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 1em;
    }
  }
  .tablist {
    background-color: red;
  }
  .bottomSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    .bottomSection__nav {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
}
