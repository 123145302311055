.casingSection {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: auto;
  padding: 60px;

  @media only screen and (max-width: 1440px) {
    max-width: 900px;
  }
  @media only screen and (max-width: 980px) {
    padding: 30px;
  }
  h4 {
    font-weight: 500;
    text-align: center;
  }
  .casingContent {
    display: flex;
    column-gap: 50px;
    margin-top: 60px;
    @media only screen and (max-width: 1440px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
      column-gap: 0;
    }
    .casingContent__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .casingContent__item_header {
        display: flex;
        margin-bottom: 40px;
        @media only screen and (max-width: 1440px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h3 {
          @media only screen and (max-width: 1440px) {
            font-size: 18px;
          }
        }
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
          @media only screen and (max-width: 1440px) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
