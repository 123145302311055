.windowsTypeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding-bottom: 100px;
  @media only screen and (max-width: 1440px) {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 980px) {
    flex-direction: column;
    padding-bottom: 0;
  }
  .windowsTypeContainer__txt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    max-width: 600px;
    padding: 40px;
    @media only screen and (max-width: 980px) {
      padding: 20px;
    }
    h3 {
      color: #19569c;
    }
    p {
      margin-top: 20px;
      font-weight: 600;
    }
  }
  .windowsTypeContainer__img {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 40px;
  }
}
