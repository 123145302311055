#typesOfWindows {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f6f9fc;
  margin-top: 100px;
  padding-top: 60px;
  h3 {
    color: #19569c;
  }
  .typeOfWindows__container {
    max-width: 1380px;
    margin-top: 60px;
  }
}
