.readySterted {
  display: flex;
  justify-content: end;
  width: 100%;
  height: 740px;
  margin-top: 40px;
  // background-image: url("../../../Assets/images/windows/home/ready-to-get-started.png");
  background-color: #f5f9fb;
  background-size: cover;
  @media only screen and (max-width: 1440px) {
    height: 500px;
  }
  @media only screen and (max-width: 980px) {
    background-color: #fff;
    justify-content: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    @media only screen and (max-width: 980px) {
      text-align: center;
      width: 90%;
      align-items: center;
    }
    h2,
    h3 {
      color: #19569c;
      margin-bottom: 40px;
      text-shadow: none;
      @media only screen and (max-width: 1440px) {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-weight: 500;

      @media only screen and (max-width: 1440px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 980px) {
        font-size: 1.8em !important;
      }
    }
    h3 {
      @media only screen and (max-width: 1440px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 980px) {
        font-size: 1em !important;
        font-weight: 400;
      }
    }

    .contentList {
      margin-bottom: 60px;
      @media only screen and (max-width: 1440px) {
        margin-bottom: 20px;
      }
      .contentList__Item {
        display: flex;
        font-weight: 600;
        margin-bottom: 25px;
        @media only screen and (max-width: 1440px) {
          margin-bottom: 10px;
        }
        p {
          margin-left: 20px;
          @media only screen and (max-width: 1440px) {
            font-size: 15px;
          }
        }
        img {
          @media only screen and (max-width: 1440px) {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
    button {
      width: 300px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
    }
  }
  .content.imageContainer {
    width: 60%;
    align-content: flex-start;
    @media only screen and (max-width: 980px) {
      display: none;
    }
  }

  @media screen and (max-width: 1680px) {
    .content {
      h2 {
        font-size: 3em;
      }
      h3 {
        font-size: 1.8em;
      }
    }
  }
}
