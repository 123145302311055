.wrapperHardware {
  max-width: 1440px;
  padding: 60px;
  margin: auto;
  @media only screen and (max-width: 1440px) {
    max-width: 900px;
    padding: 30px;
  }
  h4 {
    text-align: center;
    font-weight: 500;
  }
  .hardwareContent {
    display: flex;
    margin: auto;
    max-width: 1440px;
    column-gap: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    @media only screen and (max-width: 1440px) {
      max-width: 900px;
    }
    @media only screen and (max-width: 980px) {
      flex-direction: column;
      width: 100%;
      padding: 20px;
    }
    .leftContent {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 35px;
      border: 1px solid lightgrey;
      @media only screen and (max-width: 980px) {
        line-height: 25px;
        margin-bottom: 20px;
      }
      h3 {
        margin-top: 30px;
        margin-bottom: 30px;
        color: #158f43;
        @media only screen and (max-width: 980px) {
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 600;
        }
      }
      p {
        font-size: 1.4rem;
      }
      img {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 60%;
        @media only screen and (max-width: 980px) {
          margin-bottom: 20px;
        }
      }
    }
    .rightContent {
      display: flex;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 30px;

      flex: 1;
      @media only screen and (max-width: 980px) {
        flex-direction: column;
        flex: none;
        column-gap: 0;
        justify-content: center;
        align-items: center;
      }
      .rightContent__item {
        width: 45%;
        padding: 30px;
        border: 1px solid lightgrey;
        position: relative;
        @media only screen and (max-width: 980px) {
          width: 100%;
          height: 190px;
        }
        img {
          width: 145px;
          height: 75px;
          position: absolute;
          right: 0;
          top: 40%;
          @media only screen and (max-width: 1440px) {
            width: 100px;
            height: 50px;
            top: 60%;
          }
          @media only screen and (max-width: 980px) {
            width: 200px;
            height: 100px;
            top: 40%;
          }
        }
      }
    }
  }
}
