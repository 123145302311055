/* Home-Banner */

.home-banner {
  // padding: 0 11% 0 0;
  // width: 500px;
  width: 100%;
  @media only screen and (max-width: 989px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 940px) {
    max-width: 100%;
    text-align: center;
    width: 100%;
    padding: 20px 0px;
    box-sizing: border-box;
    .home-banner .blue-btn {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 420px) {
    padding-top: 0px;
  }
  @media screen and (max-width: 360px) {
    padding: 0px;
  }
  @media screen and (max-width: 320px) {
    padding-top: 0px;
  }

  &.kitchens {
    .welcome-text {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      flex-direction: column;

      h1 {
        font-size: 66px;
      }
    }
  }

  h1 {
    display: block;
    padding: 0 0 8px 0;
    font-size: 74px;
    font-weight: 300;
    text-shadow: 3px 3px 4px #3e3e3e, 0px 0px 14px white;

    @media (max-width: 1550px) {
      font-size: 64px;
    }

    @media (max-width: 1440px) {
      padding-bottom: 0px;
    }

    @media screen and (max-width: 1367px) {
      font-size: 50px;
      padding-bottom: 0px;
    }
    @media (max-width: 940px) {
      padding: 0 0 10px 0;
      font-size: 50px;
      font-size: 50px;
      text-shadow: 2px 2px 2px #828282;
    }
    @media (max-width: 767px) {
      padding: 0 0 0px 0;
      font-size: 30px;
    }
    @media (max-width: 420px) {
      padding: 0 0 0px 0;
      font-size: 28px;
      font-weight: 400;
    }
    @media (max-width: 375px) {
      font-size: 24px;
    }
    @media (max-width: 360px) {
      font-size: 25px;
    }
    @media (max-width: 320px) {
      font-size: 20px;
      margin: 0px;
    }
  }
}

.laptop-box {
  z-index: 4;
  position: relative;
  @media (max-width: 989px) {
    margin-top: 0px;
    width: 100%;
  }

  .desktop-visible {
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    .laptop-img {
    }
  }
}

.laptop-box:hover .youtube-icon {
  transform: translate(-50%, -50%) scale(1);
}

.youtube-icon {
  cursor: pointer;
  transition: all 0.5 ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) scale(0.9);
  z-index: 2;
}

.desktop-visible .youtube-icon {
  top: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

.laptop-box:hover .desktop-visible .youtube-icon {
  transform: translate(-50%, -50%) scale(1);
}

.laptop-img-mobile {
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 989px) {
    width: 540px;
  }
}

.laptop-img {
  max-width: 100%;
  z-index: 10;
  cursor: pointer;
  @media screen and (max-width: 1875px) {
    max-width: 720px;
  }

  @media screen and (max-width: 1642px) {
    max-width: 680px;
  }

  @media screen and (max-width: 1577px) {
    max-width: 640px;
  }

  @media screen and (max-width: 1521px) {
    max-width: 570px;
  }

  @media screen and (max-width: 1362px) {
    max-width: 460px;
  }

  @media screen and (max-width: 1244px) {
    max-width: 460px;
  }

  @media screen and (max-width: 1185px) {
    max-width: 350px;
  }

  @media screen and (max-width: 1080px) {
    max-width: 270px;
  }
}

.d-flex {
  display: flex;
  // @media screen and (max-width:1024px) {
  //     flex-direction: column;
  // }
}

.float-left {
  float: left;
}

.checkmark-box {
  @media screen and (max-width: 1024px) {
    height: 300px;
  }
  @media screen and (max-width: 989px) {
    .right-box {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    display: flex;
    height: fit-content;
    flex-direction: column;
  }
}

.checkmark-box h4 {
  position: relative;
  font-weight: 800;
  z-index: 10;
  font-size: 1.55rem;
  margin-top: 3rem;
  color: #1d3c5a;
  @media screen and (max-width: 1362px) {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }
}

.checkmark-list {
  z-index: 10;
  position: relative;
  margin-top: 20px;

  li {
    margin: 8px 0px;
    position: relative;
    z-index: 10;
    font-size: 1.2rem;
    color: #1d3c5a;
  }

  @media screen and (max-width: 1344px) {
    li {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    text-align: left;
    margin: auto !important;
  }
  @media screen and (max-width: 900px) {
    width: 80%;
    li {
      font-weight: 300;
      font-family: "Quicksand";
      color: #0d3d5d;
      padding: 0px !important;
      line-height: 20px !important;
      font-size: 18px !important;

      img {
        margin-top: -5px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    width: 95%;
  }
}

.text-slider {
  font-family: "Quicksand", serif;

  b {
    color: #42c4e4;
    font-size: 2rem;
    @media screen and (max-width: 940px) {
      font-size: 2.25rem;
    }
    @media screen and (max-width: 989px) {
      font-size: 2.25rem;
    }
    @media screen and (max-width: 420px) {
      font-size: 1.75rem;
    }
  }
}

.home-start {
  .checkmark-list li {
    font-weight: 300;
    font-family: "Quicksand";
    color: #0d3d5d;

    b {
      font-weight: 400;
    }
  }
}

.home-banner .desktop-btn {
  display: block;
  text-align: center;
  margin-top: 2rem;
  // max-width: 50%;
  font-weight: 400;
  font-size: 1.25rem;
  position: relative;
  z-index: 10;
  @media screen and (max-width: 1024px) {
    margin: 20px auto;
  }
  @media screen and (max-width: 676px) {
    display: none;
  }
}

// .white-blob{
//   background: url(../../Assets/images/banner-white-background-blob.png) repeat center center;
// }
.right-box {
  position: relative;
}

.white-blob {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  transform: translate(-50%, -30%);
  @media screen and (max-width: 1482px) {
    transform: translate(-66%, -30%);
  }
  @media screen and (max-width: 1280px) {
    width: 500px;
    transform: translate(-50%, 0px);
  }
  @media screen and (max-width: 1024px) {
    left: 50%;
  }
}

.home-banner p {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-family: "Quicksand", serif;
  font-style: italic;
  padding: 0 0 35px 0;
}

.home-banner .bottom-items {
  font-size: 24px;
  line-height: 28px;
  color: #42c4e4;
  font-weight: 600;
  padding: 0 0 20px 0;
  position: relative;
  z-index: 10;
  text-align: center;
  width: 100%;
  // @media (max-width:1024px) {
  //   width:100%;
  // }
  @media (max-width: 940px) {
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  @media (max-width: 989px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 360px) {
    font-size: 15px;
    line-height: 15px;
  }

  span {
    font-weight: 400;
    color: rgb(37, 37, 37);
    @media (max-width: 940px) {
      font-size: 24px;
      line-height: 24px;
    }
    @media (max-width: 989px) {
      font-size: 16px;
      line-height: 16px;
    }
    @media (max-width: 360px) {
      font-size: 15px;
      line-height: 15px;
    }
  }
}

// .home-banner .bottom-items span {
//   margin: 0 10px;
// }

.slick-prev {
  left: 0px;
  width: 28px;
  height: 28px;
}

.slick-next {
  right: 0px;
  width: 28px;
  height: 28px;
}

.slick-prev:before,
.slick-next:before {
  color: #26aede;
}

.works-slider {
  .slick-prev {
    left: 20px;

    &:before {
      font-size: 30px;
    }
  }

  .slick-next {
    right: 20px;

    &:before {
      font-size: 30px;
    }
  }
}
.works-slider.video {
  .slick-prev {
    left: 2px;
    top: 45%;
    &:before {
      font-size: 30px;
    }
  }

  .slick-next {
    right: 2px;
    top: 45%;
    &:before {
      font-size: 30px;
    }
  }
}
/* Home-Intro */

.home-intro {
  position: relative;
  padding: 40px 0px 110px 0px;
  text-align: center;

  h3.quicksand {
    font-family: "quicksand";
  }
}

.home-intro .container {
  position: relative;
  z-index: 5;
}

.home-intro:before {
  content: "";
  display: block;
  width: 570px;
  height: 240px;
  background: url(../../Assets//images/grid.png) repeat center center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.home-intro .top-cnt {
  position: relative;
  display: table;
  margin: 0 auto;
}

.home-intro .top-cnt .logo {
  width: 185px;
  height: 120px;
  font-size: 0;
  line-height: 0;
  display: inline-block;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    text-indent: unset;
    img {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .works-slider {
    .latest-work-slider-item {
      height: 370px;
    }
  }

  .works-slider.video {
    .slick-prev {
      top: 40%;
    }
  
    .slick-next {
      top: 40%;
    }
  }


}

.mobile-visible h4 {
  color: #757575;
}

.home-intro .top-cnt h2 {
  padding: 0 0 30px 0;
  color: #424242;
  @media screen and (max-width: 989px) {
    font-size: 39px;
    line-height: 34px;
    font-weight: 300;
  }
}

.slick-arrow.slick-prev {
  z-index: 999;
}

.home-intro .top-cnt h3 {
  padding: 0 0 30px 0;
}

.home-intro p {
  padding: 0 20px 45px 20px;
  max-width: 980px;
  margin: 0 auto;
  display: block;
}

.home-intro .blue-btn {
  margin: 0 auto;
}

@media only screen and (max-width: 1140px) {
  .home-intro .top-cnt .logo {
    position: static;
    margin: 0 auto 0px auto;
    display: block;
  }
}

@media only screen and (max-width: 989px) {
  .home-intro {
    padding: 50px 0;
  }
  .slick-track .slick-slide.slick-current + .slick-active {
    transform: scale(1) !important;
  }
}

/* Home Video */

// .home-video {
//   position: relative;
//   height: 100vh;
//   background: url(../../Assets//images/home_video_background.png) no-repeat center center;
//   background-size: cover;
//   text-align: center;
// }
.home-video img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.home-video .watch-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 48px;
  line-height: 52px;
  color: #424242;
}

.home-video .watch-btn span {
  display: block;
  padding: 90px 0 0 0;
  position: relative;
}

.home-video .watch-btn span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
}

.home-video .watch-btn span:after {
  content: "\f04b";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 30px;
  line-height: 80px;
  display: block;
  text-align: center;
  color: #ffffff;
  font-family: "FontAwesome", Georgia, serif;
  text-indent: 3px;
}

.home-video .watch-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 10;
}

.home-video .watch-btn:hover span:before {
  background-image: -moz-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
  background-image: -ms-linear-gradient(
    -90deg,
    rgb(70, 191, 238) 0%,
    rgb(41, 219, 224) 100%
  );
}

/* Home Testimonials */

.home-testimonials {
  position: relative;
  // min-height: 100vh;
  padding-top: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    padding-top: 20px;
  }
  .blue-btn {
    z-index: 99;
    position: relative;
  }
}

.home-testimonials .container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.home-testimonials .container:before,
.home-testimonials .container:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 560px;
  height: 249px;
  background: url(../../Assets//images/grid.png) repeat 0 0;
}

.home-testimonials .container:before {
  left: 0;
  margin-top: -75px;
}

.home-testimonials .container:after {
  right: 0;
  margin-top: 75px;
}

.home-testimonials .sub-title {
  padding: 0 13px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  display: table;
  background: #747d84;
  margin: 0 auto;
}

.home-testimonials h2 {
  padding: 30px 0 70px 0;
}

.home-testimonials .blue-btn {
  margin: 0 auto;
}

.testimonial-slider {
  margin: 0 auto 0px auto;
  padding: 0 0 60px 0;
  position: relative;
  display: block;
  // height: 430px;
  z-index: 10;
  width: 100%;

  .slick-slider {
    position: relative;
    z-index: -1;
    height: min-content;
  }
  .slick-slide {
    height: min-content;
    margin: 0px;
  }
}

.testimonial-slider:after {
  content: "";
  display: block;
  clear: both;
}

.testimonial-slider .owl-item {
  width: 30%;
  height: auto;
  position: relative;
  float: left;
}

.testimonial-slider .owl-item.center .box {
  // transform: scale(1);
}

.testimonial-slider .box {
  position: relative;
  // margin: 0 25px;
  width: auto;
  min-height: 330px;
  // transform: scale(0.6);
  transition: all 0.3s;
  box-sizing: border-box;
  border: solid 1px #dfe0e1;
  border-bottom-width: 6px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  display: flex !important;
  align-items: flex-end;
  align-content: center;
  flex-wrap: wrap;
  text-align: left;
  padding: 30px 25px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

.testimonial-slider .box:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 25px;
  width: 26px;
  height: 17px;
  background: url(../../Assets//images/quotes.png) no-repeat 0 0;
  display: block;
}

.testimonial-slider {
  .box:after {
    content: attr(data-number);
    position: absolute;
    bottom: 10px;
    right: 20px;
    display: block;
    font-size: 70px;
    line-height: 74px;
    color: #434343;
    font-weight: 300;
    text-shadow: 3.993px 3.009px 2px rgba(216, 216, 216, 0.9);
  }

  .slick-center {
    .box:after {
      bottom: 10px;
    }
  }
}

.text-slider {
  font-family: "Bodoni Moda", "Josefin Sans", "Quicksand", serif;
  width: 100%;
  margin: auto;
  font-weight: 700;
  letter-spacing: -2px;

  b {
    color: #42c4e4;
    font-size: 2rem;
    margin-bottom: 5px;
    @media screen and (max-width: 940px) {
      font-size: 2.25rem;
    }
    @media screen and (max-width: 989px) {
      font-size: 2.25rem;
    }
    @media screen and (max-width: 420px) {
      font-size: 1.85rem;
    }
    @media screen and (max-width: 360px) {
      font-size: 1.65rem;
    }
  }

  .slick-dots li button:before {
    font-family: initial;
    color: transparent;
  }

  .slick-dots li button {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 2px solid #c2c2c2;
    overflow: hidden;
  }

  .slick-dots li {
    margin: 0px 0px;
  }

  .slick-dots li.slick-active button:before {
    background: #42c4e4;
    border-radius: 50%;
    margin: 2px;
    color: transparent;
    width: 11px;
    height: 11px;
  }
}

.home-recent-work {
  text-align: center;
  margin: 6rem auto 4rem;

  h1 {
    font-size: 34pt;
    @media screen and (max-width: 420px) {
      font-size: 35px;
    }
  }
  h2 {
    @media screen and (max-width: 650px) {
      width: 70%;
      margin: auto;
      padding: 30px;
    }
    @media screen and (max-width: 420px) {
      width: 70%;
      margin: auto;
    }
  }

  @media screen and (max-width: 420px) {
    margin: 3rem auto 1rem;
  }
}

.award-img {
  max-height: 10rem;
}
// @media screen and (max-width:760px) {

//   .award-img {
//     height: 6rem;
//   }

// }
@media screen and (max-width: 420px) {
  .google-img {
    width: 60%;
    margin: auto;
  }
  .award-img {
    height: 4rem;
  }
  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 10px !important;
  }
}

.slick-list {
  padding: 0px !important;
}

.testimonial-slider .box .image {
  position: absolute;
  top: 10px;
  right: -25px;
  width: 75px;
}

.testimonial-slider .box .image img {
  width: 100%;
  height: auto;
  display: block;
}

.testimonial-slider .box p {
  padding: 0 0 20px 0;
  line-height: 30px;
  color: #434343;
}

.testimonial-slider .box .name {
  color: #434343;
  font-family: Georgia, serif;
  font-weight: bold;
  display: block;
  width: 100%;
  margin: 0 0 5px 0;
}

.testimonial-slider .box .rating {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  color: #ecc31f;
  font-size: 20px;
}

.testimonial-slider .box .rating .fa {
  margin-right: 3px;
}

.testimonial-slider .owl-nav {
  display: none;
}

.testimonial-slider .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.testimonial-slider .owl-dots .owl-dot {
  margin: 0 0 0 15px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: solid 1px #b7b7b7;
  border-radius: 50%;
  display: block;
  font-size: 0;
  line-height: 0;
  text-indent: -100%;
  transition: all 0.2s;
  cursor: pointer;
}

.viewMoreContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slider .owl-dots .owl-dot:first-child {
  margin: 0;
}

.testimonial-slider .owl-dots .owl-dot.active {
  border: 0;
  width: 14px;
  height: 14px;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(55, 206, 227) 0%,
    rgb(41, 220, 224) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(55, 206, 227) 0%,
    rgb(41, 220, 224) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(55, 206, 227) 0%,
    rgb(41, 220, 224) 100%
  );
}

.latest-work-slider-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media only screen and (max-width: 1800px) {
  .home-testimonials {
    display: block;
  }
  .testimonial-slider .box {
    transform: scale(1);
  }
  .testimonial-slider .owl-item.center .box {
    transform: scale(1);
  }
  .testimonial-slider .owl-item {
    width: 50%;
  }
}

@media only screen and (max-width: 960px) {
  .testimonial-slider {
    margin-bottom: 50px;
    padding-bottom: 80px;
  }
  .testimonial-slider .owl-item {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .testimonial-slider .box {
    font-size: 12px;
    line-height: 16px;
  }
  .testimonial-slider .box p {
    line-height: 16px;
  }
}

.slick-track {
  .slick-slide {
    transform: scale(0.7);
    transition: 0.5s ease all;
    @media only screen and (max-width: 989px) {
      transform: scale(1);
    }
  }
}

.slick-track .slick-slide.slick-current + .slick-active {
  .box {
    &:after {
      bottom: 10px;
    }
  }
}

.home {
  .home-intro {
    .top-cnt {
      max-width: 1200px;
    }
  }
}

.guaranteeList {
  margin-top: 20px;
  width: 600px;
  text-align: left;
  margin-bottom: 30px;
}

@media only screen and (max-width: 989px) {
  .testimonial-slider .box {
    margin: 0px;

    &:after {
      bottom: 0px;
    }

    .image {
      right: 10px;
    }
  }
  .viewMoreContainer {
    padding-bottom: 50px;
  }
  .guaranteeList {
    width: 400px;
  }
}

@media only screen and (max-width: 425px) {
  .guaranteeList {
    width: 200px;
  }
}

.banner-cover {
  width: 100%;
  height: 100%;
  // background: rgba(0,0,0, .4);
  background: rgb(0, 0, 0);
  // background: linear-gradient(90deg, rgba(255, 255, 255, .7) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 10%,
    rgb(255, 255, 255, 0.25) 100%
  );
  position: absolute;
  z-index: 0;
}

.btn-no-wrap {
  white-space: nowrap;
}

.creator-features-section {
  &--flooring {
    .row {
      &:first-child {
        .image {
          transform: scale(0.9);
        }
      }
      .image {
        transform: scale(1.2);
      }
    }
  }
}

.how-it-works-section {
  &--painting {
    .how-it-works-list_item:nth-child(3) img {
      @media screen and (min-width: 1061px) {
        transform: scale(1.5);
      }
    }
  }
  &--kitchens {
    .how-it-works-list_item:first-child span {
      background-size: cover;
    }
    .how-it-works-list_item:nth-child(3) div {
      @media screen and (max-width: 1061px) {
        margin-bottom: 36px;
      }
      img {
        transform: scale(1.1);
      }
      @media screen and (max-width: 989px) {
        margin-top: -60px;
      }
    }
  }
}

.home-section--bathrooms {
  .home-section_photograph {
    display: none;
  }
}

.call-to-action-section {
  &--painting {
    @media screen and (max-width: 989px) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        inset: 0;
        background: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.slick-center {
  transform: scale(1) !important;
}
.testimonials-user {
  display: flex;
  .testimonials-info {
    display: flex;
    flex-direction: column;
  }
  .testimonials-photo {
    margin-right: 12px;
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #46bfee;
  }
}

.content-image-missed {
  position: relative;
  &::after {
    content: "";
    inset: 0;
    position: absolute;
    background-image: url("/excelAssets/dummyimage.jpg");
    background-position: center;
    background-size: cover;
  }
  &.image {
    &::after {
      z-index: 999;
    }
  }
  &.enjoyImg {
    &::after {
      margin-top: -600px;
    }
  }
}

.about-section-3 .cnt p .call-to-action_text {
  span {
    display: inline;
  }
}

.video-preview-wrapper {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-preview-box {
  padding: 8px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 320px;
  height: 180px;
  &::after {
    content: "";
    inset: -14px;
    border-radius: 4px;
    border: 1px dashed #42c4e4;
    position: absolute;
  }
  &::before {
    content: "";
    inset: 0px;
    background: rgb(0, 0, 0, 0.7);
    position: absolute;
    transition: 0.3s ease-out;
  }
  &_video {
    opacity: 0.7;
    transition: 0.3s ease-out;
  }
  &--playing {
    &::before {
      background: rgb(0, 0, 0, 0);
    }
    .video-preview-box_video {
      opacity: 1;
    }
    .video-preview-controls {
      opacity: 0;
    }
  }
}

.video-preview-controls {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  opacity: 1;
  transition: 0.3s ease-out;
  &_label {
    white-space: nowrap;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
  }
  &_play {
    width: 48px;
    height: 48px;
  }
}
