.enjoyNewWindow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0 40px 0px;
  margin-bottom: 70px;
  width: 100%;
  height: 750px;
  background-image: url("../../../Assets/images/windows/home/background-enjoy.png");
  background-size: cover;
  @media only screen and (max-width: 1440px) {
    justify-content: space-between;
    height: auto;
  }
  .enjoyTxt {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    width: 30%;
    margin-right: 50px;
    @media only screen and (max-width: 1440px) {
      padding-left: 30px;
    }
    @media only screen and (max-width: 980px) {
      width: 100%;
      margin-right: 0px;
      padding-left: 0;
      align-items: center;
    }
    h2 {
      color: #19569c;
      font-weight: 500;
      text-shadow: none;
      @media only screen and (max-width: 1440px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 980px) {
        width: 100%;
        text-align: center;
      }
    }
    .enjoyTxt__windows {
      display: flex;
      column-gap: 20px;
      @media only screen and (max-width: 980px) {
        column-gap: 10px;
        width: 100%;
        justify-content: center;
      }
      @media only screen and (max-width: 600px) {
        column-gap: 10px;
        width: 80%;
      }
    }
    button {
      width: 225px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      background: linear-gradient(#0d8bc1, #19569c);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 23px;
      line-height: 30px;
      font-family: "Quicksand", sans-serif;
      font-weight: 300;
      cursor: pointer;
    }
    img {
      cursor: pointer;
      @media only screen and (max-width: 980px) {
      }
      &:hover {
        filter: brightness(1.8) saturate(1.5) hue-rotate(-30deg);
      }
    }
    img.active {
      filter: brightness(1.5) saturate(1.5) hue-rotate(-30deg);
    }
  }

  .wndType {
    width: 60%;
    &:hover {
      filter: none !important;
    }

    @media only screen and (max-width: 1440px) {
      width: 50%;
    }
    @media only screen and (max-width: 980px) {
      width: 90%;
      align-self: flex-end;
      margin-right: -4%;
    }
  }
}
